import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import ConsultationCta from "../../components/ConsultationCta"
import SoftwareCta from "../../components/SoftwareCta"

const features = [
  {
    title: "Poster Performance",
    img: "feature1",
    content: `<p>Content and more content and more content.</p>
              <ul>
                <li>Sell performance on a per user basis</li>
                <li>Set targets and hours for all users</li>
                <li>Be able to see whos above and below target</li>
              </ul>
              `,
  },
  {
    title: "Poster targets",
    img: "feature2",
    content: `<p>Where your users stand and their performance relative to the targets. Content and more content and more content and more.</p>`,
  },
  {
    title: "Export all data",
    img: "feature3",
    content: `<p>Everything in Lister is exportable. Content and more content and more content and more content and more content and more content and more content. Content and more content..</p>`,
  },
  {
    title: "Top sales",
    img: "feature4",
    content: `<p>Content and more content and more content.</p>
              <ul>
                <li>Sell performance on a per user basis</li>
                <li>Set targets and hours for all users</li>
                <li>Be able to see whos above and below target</li>
              </ul>
              `,
  },

  {
    title: "Stores sales overview",
    img: "feature5",
    content: `<p>Content and more content and more content.</p>
              <ul>
                <li>Sell performance on a per user basis</li>
                <li>Set targets and hours for all users</li>
                <li>Be able to see whos above and below target</li>
              </ul>
              `,
  },
]

const Feature = ({ data }) => (
  <Layout>
    <SEO title="Software Feature" />

    <div className="container feature">
      <Link to="/software" className="link--arrow-left">
        Software
      </Link>

      <h1 className="feature-title">Feature</h1>

      <ul className="feature-list">
        {features.map(({ title, img, content }, index) => {
          const imgFluid = data[img].childImageSharp.fluid

          return (
            <li className="feature-item grid" key={index}>
              <figure className="figure-gradient feature-img">
                <Img fluid={imgFluid} alt={title} />
              </figure>
              <div className="feature-content">
                <h2 className="h4--bold">{title}</h2>
                <div dangerouslySetInnerHTML={{ __html: content }} />
              </div>
            </li>
          )
        })}
      </ul>
    </div>
    <ConsultationCta />
  </Layout>
)

export const query = graphql`
  query {
    feature1: file(relativePath: { eq: "feature-1.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 288) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    feature2: file(relativePath: { eq: "feature-2.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 288) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    feature3: file(relativePath: { eq: "feature-3.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 288) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    feature4: file(relativePath: { eq: "feature-4.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 288) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    feature5: file(relativePath: { eq: "feature-5.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 288) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Feature
